var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoaded)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary darken-2"}})],1):_c('div',[_c('EditDialog',{attrs:{"max-width":"700","save-button-text":"Create","title":"New template"},on:{"save":function($event){return _vm.loadItems()}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('TemplateForm',{attrs:{"project-id":_vm.projectId,"item-id":"new","need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}]),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('EditDialog',{attrs:{"save-button-text":"Run","title":"New Task"},on:{"save":_vm.onTaskCreated},scopedSlots:_vm._u([{key:"title",fn:function(ref){return [_c('span',{staticClass:"breadcrumbs__item"},[_vm._v(_vm._s(_vm.templateAlias))]),_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('span',{staticClass:"breadcrumbs__item"},[_vm._v("New Task")])]}},{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('TaskForm',{attrs:{"project-id":_vm.projectId,"item-id":"new","template-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}]),model:{value:(_vm.newTaskDialog),callback:function ($$v) {_vm.newTaskDialog=$$v},expression:"newTaskDialog"}}),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v("Task Templates")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem('new')}}},[_vm._v("New template")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.settingsSheet = true}}},[_c('v-icon',[_vm._v("mdi-cog")])],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.filteredHeaders,"items":_vm.items,"hide-default-footer":"","items-per-page":Number.MAX_VALUE},scopedSlots:_vm._u([{key:"item.alias",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/project/" + _vm.projectId + "/templates/" + (item.id))}},[_vm._v(" "+_vm._s(item.alias)+" ")])]}},{key:"item.ssh_key_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.keys.find(function (x) { return x.id === item.ssh_key_id; }).name)+" ")]}},{key:"item.inventory_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.inventory.find(function (x) { return x.id === item.inventory_id; }).name)+" ")]}},{key:"item.environment_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.environment.find(function (x) { return x.id === item.environment_id; }).name)+" ")]}},{key:"item.repository_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.repositories.find(function (x) { return x.id === item.repository_id; }).name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pl-1 pr-2",attrs:{"text":"","color":"black"},on:{"click":function($event){return _vm.createTask(item.id)}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-play")]),_vm._v(" Run ")],1)]}}])}),_c('TableSettingsSheet',{attrs:{"table-name":"project__template","headers":_vm.headers},on:{"change":_vm.onTableSettingsChange},model:{value:(_vm.settingsSheet),callback:function ($$v) {_vm.settingsSheet=$$v},expression:"settingsSheet"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }